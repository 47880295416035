import React from 'react'
import { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import Nav from '../Navbar/Nav';
import './header.css'
function Header() {

    const downloadPDF = () => {
        window.open("Image/Sanvee PDF_compressed.pdf");
    };
    // const { useState } = React;
    // const getInitialState = () => {
    //     const value = "Bootstrap";
    //     return value;
    // };

    // const [value, setValue] = useState(getInitialState);

    // const handleChange = (e) => {
    //     setValue(e.target.value);
    // };

    const [isScrolled, setIsScrolled] = useState(false);

    useEffect(() => {
      const handleScroll = () => {
        const scrollTop = window.pageYOffset;
  
        setIsScrolled(scrollTop > 0);
      };
  
      window.addEventListener('scroll', handleScroll);
  
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }, []);


    return (

        <div className='bg-light-200 md:mt-0  md:z-10 md:sticky 	h-48' >
            <div className='md:hidden  '><Nav></Nav></div>
            <div className=' xs:mt-6 grid grid-flow-col justify-stretch xs:grid-cols-1   pt-8 '>

                <div className='xs:hidden md:pl-16  flex items-center'> <img src="Image/call icone.svg"></img>
                    <span className='myfont  pl-8'> call for order <br /> +91 8369791722</span>
                </div>
                <div className=' ' >
                    <img className=' mx-auto xs:ml-8 ' src="Image/logo.png"></img>
                </div>
                <div className="flex mx-auto   xs:hidden"  >
                    <img className='pl-4' src="Image/fb.svg"></img>
                    <img className='pl-4' src="Image/insta.svg"></img>
                    <img className='pl-4' src="Image/twitter.svg"></img>
                </div>
            </div>
            {/* <div className='bg-white border  ml-[300px] self-stretch  rounded-[60px] stroke-[#FEBD113D] xs:hidden  absolute px-10 mt-[60px]  '>
                <ul class="flex  items-center gap-4">

                    <NavLink to="/" className="font-bold  items-center px-3   py-7  text-slate-700 duration-700  hover:bg-light-500 hover:text-white">Home</NavLink>
                    <NavLink to="/About" className="font-medium px-3  py-7  text-slate-700  duration-700 hover:bg-light-500 hover:text-white">About us</NavLink>
                    <NavLink to="/Product1" className="font-medium px-3  py-7  text-slate-700 duration-700  hover:bg-light-500 hover:text-white">Products</NavLink>
                    <NavLink to="/Kaushal" className="font-medium px-3  py-7  text-slate-700 duration-700  hover:bg-light-500 hover:text-white">Kaushal Ingredients</NavLink>
                    <NavLink to="/Gallery" className="font-medium px-3  py-7  text-slate-700 duration-700  hover:bg-light-500 hover:text-white">Gallery</NavLink>
                    <NavLink to="/Contact" className="font-medium px-3  py-7   text-slate-700 duration-700  hover:bg-light-500 hover:text-white">Contact us</NavLink>
                    <button onClick={downloadPDF} className=" font-bold px-3 py-7  text-light-500 duration-700  hover:bg-light-500 hover:text-white">Download Boucher</button>

                </ul>
            </div> */}

            <div className={`bg-white px-10 w-[950px] flex   gap-4   text-center  stroke-[#FEBD113D] xs:hidden mt-[60px] rounded-[60px]  h-[80px] mx-auto ${isScrolled  ? 'header-scroll' : ' '} `}>
                <NavLink to="/" className="font-bold   px-3   py-7  text-slate-700 duration-700  hover:bg-light-500 hover:text-white">Home</NavLink>
                <NavLink to="/About" className="font-medium px-3  py-7  text-slate-700  duration-700 hover:bg-light-500 hover:text-white">About us</NavLink>
                <NavLink to="/Product1" className="font-medium px-3  py-7  text-slate-700 duration-700  hover:bg-light-500 hover:text-white">Products</NavLink>
                <NavLink to="/Kaushal" className="font-medium px-3  py-7  text-slate-700 duration-700  hover:bg-light-500 hover:text-white">Kaushal Ingredients</NavLink>
                <NavLink to="/Gallery" className="font-medium px-3  py-7  text-slate-700 duration-700  hover:bg-light-500 hover:text-white">Gallery</NavLink>
                <NavLink to="/Contact" className="font-medium px-3 py-7   text-slate-700 duration-700  hover:bg-light-500 hover:text-white">Contact us</NavLink>
                <button onClick={downloadPDF} className=" font-bold px-3 py-7  text-light-500 duration-700  hover:bg-light-500 hover:text-white">Download Boucher</button>
            </div>
        </div>

    )
}

export default Header