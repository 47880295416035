import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import './BannerCarousel.css'

import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';


const settings = {
    dots: false,
    fade: true,
    infinite: true,
    speed: 1000,
    autoplay: true,
    autoplaySpeed: 2000,
    slidesToShow: 1,
    slidesToScroll: 2,
    
  };

export default () => (
    
    <Slider {...settings}>
    <div>
     <LazyLoadImage width="100%" effect="blur"  alt="Image" className="w-full" src="Image/sanvee1.svg" placeholderSrc="Image/BannerSlider/san1.png"  />
    </div>
    <div>
    <LazyLoadImage width="100%" effect="blur"  alt="Image" className="w-full" src="Image/sanveeBanner2.svg" placeholderSrc="Image/BannerSlider/sanvee1.png"  />
      {/* <img className="w-full" src="Image/sanveeBanner2.svg" alt="Kitten" /> */}
    </div>
    <div>
    <LazyLoadImage width="100%"  effect="blur"  alt="Image" className="w-full" src="Image/sanveeBanner3.svg" placeholderSrc="Image/BannerSlider/san1.png"  />
      {/* <img className="w-full" src="Image/sanveeBanner3.svg" alt="Kitten" /> */}
    </div>
    <div>
    <LazyLoadImage width="100%" effect="blur"  alt="Image" className="w-full" src="Image/sanveeBanner4.svg" placeholderSrc="Image/BannerSlider/san1.png"  />
      {/* <img className="w-full" src="Image/sanveeBanner4.svg" alt="Kitten" /> */}
    </div>
    {/* <div>
      <img className="w-full" src="Image/sanveeBanner5.svg" alt="Kitten" />
    </div> */}
  </Slider>
);
