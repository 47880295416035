import React from 'react'
import Home from '../Home/Home'
import Footer from '../Footer/Footer'
import Header from '../Header/Header'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import About from '../About/About'
import Contact from '../Contact/Contact'
import Gallery from '../Gallery/Gallery'
import Product1 from '../product 1/Product1'
import KaushalIngradients from '../KaushalIngradients/KaushalIngradients'

function Page() {
    return (
        <div><BrowserRouter>
         <div className='xs:mb-[-80px]'><Header />
    </div>
            <Routes  >
                <Route exact path="/" element={<Home />}/>
                <Route exact path="/About" element={<About />}/>
                <Route exact path="/Contact" element={<Contact />}/>
                <Route exact path="/Kaushal" element={<KaushalIngradients />}/>
                <Route exact path="/Gallery" element={<Gallery />}/>
                <Route exact path="/Product1" element={<Product1 />}/>

           
            </Routes>
                 {/* footer */}

     <Footer/>
        </BrowserRouter></div>
    )
}

export default Page