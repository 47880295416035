import React from 'react'

function Footer() {
    return (

        <div >
            <section >
                <div className='bg-white xs:grid-cols-1 grid grid-cols-3'>
                    <div className=' p-20 xs:pl-10 '>
                        <img src='Image/logo.png'></img>
                        <p className='pt-6 w-[250px] font-semibold'>Where every flavour tells a story.
                            For the love of delicious Test.</p>
                        <div className='flex'>
                            <img src='Image/call.svg'></img>
                            <h2 className='p-8'>(+91) 8369791722   <br /><h2 className='pt-2' >(+91) 9892220253</h2></h2>
                        </div>
                    </div>
                    <div className='p-20 xs:pt-0 xs:pl-10'>
                        <h1 className='font-bold text-3xl'>Links</h1>
                        <ul className='pt-6'>
                            <li className=' font-medium py-1'><a href='/'>  Home</a>

                            </li>
                            <li className=' font-medium py-1'><a href='/About'>  About</a>

                            </li>
                            <li className='py-1 font-medium'><a href='/Product1'>  Products</a>

                            </li>
                            <li className='py-1 font-medium'><a href='/Kaushal'>  Kaushal Ingradients</a>

</li>
                            <li className='py-1 font-medium'><a href='/Gallery'>  Gallery</a>

                            </li>
                            <li className='py-1 font-medium'><a href='/Contact'>  Contact Us</a>

                            </li>
                        </ul>
                    </div>
                    <div className='pt-20 xs:py-10 xs:pl-10'>
                        <h1 className='font-bold text-3xl'>Official Info</h1>
                        <h2 className='pt-6  font-semibold'>REGISTER OFFICE ADDRESS</h2>
                        <p className='pt-2 w-[250px] font-normal'>Unit 90, Mahalaxmi mall
                            Plot No. 8 & 8B , Sector 35, Kamothe,
                            Navi Mumbai 410209</p>
                        <h2 className='pt-4  font-semibold'>FACTORY</h2>
                        <p className='pt-2 w-[250px] font-normal'>A 203, Silver Springs MIDC Taloja, Navi Mumbai 410208</p>

                    </div>
                </div>
            </section>
            <section>
                <div className='bg-[#E62929C7] h-20 grid grid-cols-6 xs:h-40 xs:grid-cols-1  '>
                    <div className='col-span-2 sm:p-6 gap-3 xs:p-8 flex'>
                            <img src='Image/fb (1).svg'></img>
                            <img src='Image/insta (1).svg'></img>
                            <img src='Image/twit.svg'></img>
                            <img src='Image/link.svg'></img>
                    </div>
                    <div className='col-span-4 xs:pl-10'>
                    <h2 className='sm:p-6 text-white'>© 2023 Sanvee Flavours | Designed & developed by <a  href='https://5techg.com/'>5TechG Lab</a></h2>

                    </div>
                </div>
            </section>
        </div>
    )
}

export default Footer