import React from 'react'
import { Link } from 'react-router-dom'
import { LazyLoadImage } from 'react-lazy-load-image-component'
function KaushalIngradients() {
    return (
        <div>
            <div >
            <LazyLoadImage width="100%" effect="blur"  alt="Image" className="w-full" src="Image/KaushalI.svg" />
                {/* <img className='w-full' src='Image/KaushalI.svg'></img> */}
                {/* <h1 className=''>cadcvsdv</h1> */}
            </div>

            <div className=' xs:grid-cols-1 my-20 grid grid-cols-2'>
                <div className='col-span-1'>
                    <img className='mx-auto ' src='Image/kaushal1.jpg'></img>
                </div>
                <div className='col-span-1'>
                    <h2 className='text-center sm:text-4xl md:text-5xl font-bold text-[#082161] mt-8 '>About Kaushal Ingradients</h2>
                    <p className=' my-10 mx-10 text-[#082161] font-medium leading-8 text-center  '>Established in 2014 ,India Kaushal Ingredients has gained immense expertise in supplying & trading of Flavours, colours, food ingredients etc. The supplier company is located in Navi Mumbai, Maharashtra and is one of the leading sellers of listed products. Buy Flavours, colours, food ingredients in bulk from us for the best quality products and service.</p>

                </div>

            </div>
            
            <div>
            <h1 className='text-center xs:text-4xl sm:text-4xl bg-gray-100  md:text-5xl font-bold text-[#000000] py-20 '>Top Products & Services</h1>
            <div className='grid grid-cols-4 px-10  xs:grid-cols-1  bg-gray-100 pb-40  py-4 gap-4'>
                <div class="container col-span-1  p-9 bg-white max-w-sm rounded-2xl  shadow-xl hover:shadow-2xl transition duration-300">
                    <img class="rounded-xl" src="Image/flavourk.jpg" alt="" />
                    <div class="flex justify-between items-center">
                        <div>
                            <h1 class="mt-5 text-2xl font-semibold">Flavours</h1>
                            {/* <p class="mt-2">$11.99</p> */}
                        </div>
                        <div>
                           <Link to="/Product1"> <button class="text-white text-md font-semibold mt-5  bg-light-500 py-2 px-4 rounded-lg shadow-md hover:shadow-lg transition duration-500 transform-gpu hover:scale-110 ">View more</button></Link>
                        </div>
                    </div>
                </div>
                <div class="container col-span-1 p-9 bg-white max-w-sm rounded-2xl overflow-hidden shadow-xl hover:shadow-2xl transition duration-300">
                    <img class="rounded-xl" src="Image/colourk.jpg" alt="" />
                    <div class="flex justify-between items-center">
                        <div>
                            <h1 class="mt-5 text-2xl font-semibold">Colours</h1>
                            {/* <p class="mt-2">$11.99</p> */}
                        </div>
                        <div>
                        <Link to="/Product1"> <button class="text-white text-md font-semibold mt-5  bg-light-500 py-2 px-4 rounded-lg shadow-md hover:shadow-lg transition duration-500 transform-gpu hover:scale-110 ">View more</button></Link>
                        </div>
                    </div>
                </div>
                <div class="container col-span-1  p-9 bg-white max-w-sm rounded-2xl  shadow-xl hover:shadow-2xl transition duration-300">
                <img class="rounded-xl" src="Image/foodk.jpg" alt="" />
                    <div class="flex justify-between items-center">
                        <div>
                            <h1 class="mt-5 text-2xl font-semibold">Food Ingredients</h1>
                            {/* <p class="mt-2">$11.99</p> */}
                        </div>
                        <div>
                        <Link to="/Product1"> <button class="text-white text-md font-semibold mt-5  bg-light-500 py-2 px-4 rounded-lg shadow-md hover:shadow-lg transition duration-500 transform-gpu hover:scale-110 ">View more</button></Link>
                        </div>
                    </div>
                </div>
                <div class="container col-span-1 p-9 bg-white max-w-sm rounded-2xl overflow-hidden shadow-xl hover:shadow-2xl transition duration-300">
                <img class="rounded-xl" src="Image/calciumk.jpg" alt="" />
                    <div class="flex justify-between items-center">
                        <div>
                            <h1 class="mt-5 text-2xl font-semibold">Calcium Propionate</h1>
                            {/* <p class="mt-2">$11.99</p> */}
                        </div>
                        <div>
                        <Link to="/Product1"> <button class="text-white text-md font-semibold mt-5  bg-light-500 py-2 px-4 rounded-lg shadow-md hover:shadow-lg transition duration-500 transform-gpu hover:scale-110 ">View more</button></Link>
                        </div>
                    </div>
                </div>
            </div>
            </div>
        </div>
    )
}

export default KaushalIngradients